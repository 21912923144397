import "./works.scss";

export default function Works() {
  const data = [
    {
      id: "1",
      title: "Trip Planner Buddy App",
      desc: [
        "Developed a trip planning mobile app with Python FastAPI backend, React Native frontend, and MongoDB database.",
        "Boosted trip planning page speed by 75% through efficient Redis caching, enhancing user experience.",
        "Harnessed the power of GPT 3.5-Turbo API to recommend trips to users based on their personal preferences.",
        "Integrated external APIs such as OpenWeatherMap, Google Maps to create real-time and rich user experience.",
        "Users can create and share their plans to other users of the app.",
      ],
      img: "assets/tpb-1.png",
      tech: [
        "React Native",
        "Python",
        "FastAPI",
        "MongoDB",
        "Redis",
        "GPT-3.5",
        "Agile",
        "Scrum",
        "Jira",
      ],
      github_link: "https://github.com/Ruturajnawale10/trip-planner-buddy-prototype",
    },
    {
      id: "2",
      title: "Stack Overflow Clone",
      desc: [
        "Lead a team of 7 members and created a clone of stack overflow using MERN stack.",
        " Applied the concepts of distributed systems such as kafka messaging queue, redis cache, load balancer for various features of the application.",
        "Tested various APIs in the application using Mocha",
        "For security, authenticated API requests from the users using JWT token and stored hashed passwords using Bcrypt",
      ],
      img: "assets/stack-overflow-1.png",
      tech: [
        "React.js",
        "Node.js",
        "MongoDB",
        "MySQL",
        "Express.js",
        "Redis",
        "Kafka",
        "Javascript",
        "Bootstrap",
        "Mocha",
        "JMeter",
        "Agile",
        "Scrum",
        "Jira",
      ],
      github_link: "https://github.com/Ruturajnawale10/stack-overflow",
    },
    {
      id: "3",
      title: "Etsy Clone",
      desc: [
        "Created web app with similar functionalities of Etsy app where users can buy and sell items.",
        "Other functionalities of any user include creating shop with unique name, adding items in favourites list, to checkout items, etc.",
        "For scalability of users, used Kafka and Database pooling which significantly improved the performace of the website(analyzed performance using JMeter)",
      ],
      img: "assets/etsy1.png",
      tech: [
        "React.js",
        "Node.js",
        "MongoDB",
        "MySQL",
        "Express.js",
        "Kafka",
        "Javascript",
        "Mocha",
        "JMeter",
      ],
      github_link: "https://github.com/Ruturajnawale10/etsy-web-app",
    },
    {
      id: "4",
      title: "NFT Trading Marketplace",
      desc: [
        "Used Spring MVC with React Frontend and development the web application where users can buy and sell NFT, maintain transaction history, maintain wallet supporting multiple currencies, sign in with google auth, adding items in favourites list, to checkout items, etc.",
        "Extensively used JPA ORM to manage database transactions easily in the MySQL database",
        "Used different spring annotations such as @Transactional to handle different database concurrency issues",
        "Used AWS RDS for MySQL and deployed the application on AWS EC2",
      ],
      img: "assets/nft.png",
      tech: [
        "Spring",
        "Java",
        "React.js",
        "JPA",
        "MySQL",
        "AWS",
        "Google Auth",
      ],
      github_link:
        "https://github.com/Ruturajnawale10/nft-trading-marketplace-frontend",
    },
    {
      id: "5",
      title: "Music Creation Using ML",
      desc: [
        "Created a web app similar to Google AI experiment 'The Infinite Drum Machine'.",
        " Used dimensional reduction to plot sound notes on web to creatively combine these notes and create music",
        "Similar sounds are plotted closer to each other, giving the user a sense of musical harmony.",
      ],
      img: "assets/music1.png",
      tech: [
        "Javascript",
        "Python",
        "Scikit-Learn",
        "Dimensional Reduction",
        "HTML",
        "Bokeh",
      ],
    },
  ];

  return (
    <div>
      <p className="title"> Some of my recent projects</p>
      <div className="works" id="works">
        <div className="slider">
          {data.map((d) => (
            <div>
              <h2 className="project_name">{d.title}</h2>
              <div className="container">
                <div className="item">
                  <div className="left">
                    <img
                      src={d.img}
                      alt=""
                      style={{
                        width: "100%",
                        height: 600,
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className="right">
                    <div className="rightContainer">
                      <p>
                        <ul className="bullets">
                          {d.desc.map((t) => (
                            <li>{t}</li>
                          ))}
                        </ul>
                      </p>

                      <ul className="tags">
                        {d.tech.map((t) => (
                          <li>{t}</li>
                        ))}
                      </ul>
                      <a
                        class="btn btn-dark"
                        target="_blank"
                        rel="noreferrer"
                        href={d.github_link}
                        hidden={!d.github_link}
                      >
                        Github
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className="footer">
        {" "}
        Created with <img src="assets/heart.png" alt=""></img> by me :)
      </p>
    </div>
  );
}
