import "./topbar.scss"
import {Mail} from '@material-ui/icons'

export default function Topbar( { menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
    <div className="wrapper">
        <div className="left">
            <a href="#intro" className="logo">
                Ruturaj Nawale
            </a>
            
            <div className="itemContainer">
            <Mail className="icon"/>
            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=ruturajnawale99@gmail.com" target="_blank" rel="noreferrer" style={{textDecoration:"none", color:"black"}}> ruturajnawale99@gmail.com </a>
            </div>
        </div>
        <div className="right">
            <div className="hamburger" onClick={() => {setMenuOpen(!menuOpen)}}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
        </div>
    </div>
    </div>
  )
}
