import "./portfolio.scss";

export default function Portfolio() {
  return (
    <div className="portfolio" id="portfolio">
      <p className="portfolio_title" style={{textAlign:"center", fontWeight:"bold"}}>
        {" "}
        Skills
      </p>
      <div className="skills">
        <ul>
          <li>
            <img className="skill1" src="../assets/aws.png" alt="" />
          </li>
          <li>
            <img className="skill1" src="../assets/python3.png" alt="" />
          </li>
          <li>
            <img className="skill1" src="../assets/swift.png" alt="" />
          </li>
          <li>
            <img className="skill1" src="../assets/node.png" alt="" />
          </li>
        </ul>
      </div>
      <ul>
        <li> Python </li>
        <li> Go </li>
        <li> Java</li>
        <li> Javascript</li>
        <li> Typescript</li>
        <li> React.js</li>
        <li> React Native</li>
        <li> Node.js</li>
        <li> Express.js </li>
        <li> Spring </li>
        <li> Redux </li>
        <li> MongoDB </li>
        <li> MySQL </li>
        <li> Redis Caching </li>
        <li> Apache Kafka </li>
        <li> AWS RDS</li>
        <li> REST </li>
        <li> FastAPI </li>
        <li> GraphQL </li>
        <li> HTML </li>
        <li> CSS </li>
        <li> Bootstrap </li>
        <li> Objective C </li>
        <li> Swift </li>
        <li> AWS </li>
        <li> AWS CloudFront</li>
        <li> AWS CodePipeline</li>
        <li> Git </li>
        <li> Docker </li>
        <li> JUnit </li>
        <li> Mocha </li>
        <li> Agile </li>
        <li> Scrum </li>
        <li> Jira </li>
      </ul>

      <div className="skills">
        <ul>
          <li>
            <img className="skill1" src="../assets/react-native.png" alt="" />
          </li>
          <li>
            <img className="skill1" src="../assets/xcode.png" alt="" />
          </li>

          <li>
            <img className="skill1" src="../assets/mongodb.png" alt="" />
          </li>
          <li>
            <img className="skill1" src="../assets/git.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
  );
}
