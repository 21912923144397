import "./intro.scss";
import { LinkedIn, GitHub } from "@material-ui/icons";
import Typed from "typed.js";
import React, { useEffect, useRef } from "react";

export default function Intro() {
  const interestsRef = useRef(null);
  const nameRef = useRef(null);

  useEffect(() => {
    // Typed animation for interests
    const interestsTyped = new Typed(interestsRef.current, {
      strings: [
        "Backend Development.",
        "Full Stack Development.",
        "Mobile App Development.",
        "Distributed Systems.",
      ],
      typeSpeed: 40,
      backSpeed: 10,
      loop: true,
    });

    // Typed animation for name
    const nameTyped = new Typed(nameRef.current, {
      strings: ["Ruturaj Nawale."],
      typeSpeed: 40,
      showCursor: false, // Optional: Hide the cursor for this animation
    });

    return () => {
      interestsTyped.destroy();
      nameTyped.destroy();
    };
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src="../assets/ruturaj.png" alt="" />
        </div>
        <div className="connectOn">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/ruturaj-nawale/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedIn className="connect_icons" />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/Ruturajnawale10"
                target="_blank"
                rel="noreferrer"
              >
                <GitHub className="connect_icons2" />
              </a>
            </li>
            <li>
              <a
                href="https://leetcode.com/thirtham/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="assets/leetcode.svg"
                  className="connect_icons3"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <p className="intro1">Hi there, my name is</p>
          <span>
          
            {// eslint-disable-next-line
            }<h1 ref={nameRef}></h1>
          </span>
          <p>
            I'm a Software Engineering graduate student from {" "}
            <a
              className="college"
              href="https://www.sjsu.edu/"
              target="_blank"
              rel="noreferrer"
            >
              San Jose State University
            </a>{""}, and actively looking for full-time SDE roles.
            I have experience working in backend, full stack and on iOS apps. <br></br>
            <br></br>
            I'm passionate about building scalable, efficient and robust
            systems. I love improving user experience of software. <br></br>
            <br></br>I enjoy automating things, just like I automated building
            and deploying this website using &nbsp;
            <span style={{ color: "rgb(100, 100, 255)" }}>
              AWS CodePipeline!
            </span>{" "}
            <br></br>
            <br></br>My interests lie in &nbsp;
            <span
              style={{ color: "rgb(255, 242, 115)" }}
              ref={interestsRef}
            ></span>
          </p>
        </div>
      </div>
    </div>
  );
}
